
/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
    return num < 10 ? '0' + num : num
};

/**
 * 获取当前时间年月日，如：2000-10-20
 */
export const getDate = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month = getHandledValue(d.getMonth() + 1);
    const date = getHandledValue(d.getDate());
    return year + '-' + month + '-' + date;
};

/**
 * 获取当前时间年月日时分秒，如：2000-10-20 20:20:20
 * @param {Number} timeStamp 传入的时间戳
 **/
export const getDateFromTimeStamp = (timeStamp) => {
    if (timeStamp) {
        const d = new Date(timeStamp);
        const year = d.getFullYear();
        const month = getHandledValue(d.getMonth() + 1);
        const date = getHandledValue(d.getDate());
        const hours = getHandledValue(d.getHours());
        const minutes = getHandledValue(d.getMinutes());
        const second = getHandledValue(d.getSeconds());
        return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second;
    } else {
        return '';
    }
};

/**
 * 获取当前时间月日，如：10-20
 * @param {Number} timeStamp 传入的时间戳
 **/
export const getDateFromTimeStamp2 = (timeStamp) => {
    if (timeStamp) {
        const d = new Date(timeStamp);
        const month = getHandledValue(d.getMonth() + 1);
        const date = getHandledValue(d.getDate());
        return month + '-' + date;
    } else {
        return '';
    }
};

/**
 * 获取当前时间月日，如：10-20
 * @param {Number} timeStamp 传入的时间戳
 **/
export const getDateFromTimeStamp3 = (timeStamp) => {
    if (timeStamp) {
        const d = new Date(timeStamp);
        return d.getMonth() + 1;
    } else {
        return '';
    }
};
