<template>
    <div class="main_container">
        <div class="title_container">
            <div>教材分布统计：</div>
        </div>
        <ve-histogram :data="chartData4" :settings="chartSettings4"></ve-histogram>

        <div class="title_container">
            <div>注册2月以上用户使用错词功能占比：</div>
        </div>
        <ve-histogram :data="chartData5" :settings="chartSettings5"></ve-histogram>
    </div>
</template>


<script>
    import {getDateFromTimeStamp3} from "@/utils/dateUtil";

    export default {
        name: "user",

        data() {
            return {
                chartSettings4:{
                    metrics: ['教材分布占比'],
                    dimension: ['版本']
                },
                chartSettings5:{
                    metrics: ['注册2月以上用户使用错词功能占比'],
                    dimension: ['月数']
                },
                value1: '',//选择日期
            }
        },
        mounted(){
            this.getDate14();
            this.getDate15();
        },
        methods:{
            getDate14() {
                this.$store.dispatch("data/getData14", {});
            },
            getDate15() {
                this.$store.dispatch("data/getData15", {});
            },
        },
        computed:{
            // 教材分布统计
            chartData4() {
                let rows = this.$store.state.data.data14.map((item) => {
                    let row = {};
                    row['版本'] = item.bookName;
                    row['教材分布占比'] = item.count * 1.0 / item.total;
                    return row;
                })
                let columns = ['版本', '教材分布占比'];
                return {rows, columns}
            },
            // 注册2月以上用户使用错词功能占比
            chartData5() {
                let rows = this.$store.state.data.data15.map((item) => {
                    let row = {};
                    row['月数'] = getDateFromTimeStamp3(item.createTime) + "月";
                    row['注册2月以上用户使用错词功能占比'] = item.count * 1.0 / item.total;
                    return row;
                })
                let columns = ['月数', '注册2月以上用户使用错词功能占比'];
                return {rows, columns}
            },
        }
    }
</script>

<style scoped>
    .main_container {
        padding: 10px 20px 20px 20px;
    }

    .title_container {
        font-weight: bolder;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .right_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user_count {
        font-size: 18px;
        margin-left: 30px;
    }

</style>
